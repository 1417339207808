import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_FILM_EPISODE = 'getFilmEpisode'; //
export const GET_FILM_EPISODES = 'getFilmEpisodes'; //
export const STORE_FILM_EPISODE = 'storeFilmEpisode'; //
export const UPDATE_FILM_EPISODE = 'updateFilmEpisode'; //
export const GET_FILM_EPISODE_URL_LIST = 'getFilmEpisodeUrlList'; //

// [NAVIGATION MUTATIONS]
export const SET_FILM_EPISODE = 'setFilmEpisode'; //
export const SET_FILM_EPISODES = 'setFilmEpisodes'; //
export const SET_FILM_EPISODE_ERROR = 'setFilmEpisodeError'; //
export const SET_FILM_EPISODE_PAGE = 'setFilmEpisodePage'; //

const state = {
  filmEpisode: {},
  filmEpisodes: [],
  filmEpisodeMeta: {},
  filmEpisodeError: null,
  filmEpisodePage: {page: 1, film_id: null}
};

const mutations = {
  [SET_FILM_EPISODE](state, payload){
    state.filmEpisode = payload;
  },
  [SET_FILM_EPISODES](state, payload){
    state.filmEpisodes = payload.data;
    state.filmEpisodeMeta = payload.meta;
  },
  [SET_FILM_EPISODE_ERROR](state, payload){
    state.filmEpisodeError = payload;
  },
  [SET_FILM_EPISODE_PAGE](state, payload){
    state.filmEpisodePage = payload;
  },
};

const actions = {
  [GET_FILM_EPISODE]( context, id ) {
    return GetAction( `manager/episodes`,
    `${id}`,
    context,
    SET_FILM_EPISODE_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_FILM_EPISODE, response.data );
      }
    } );
  },
  [GET_FILM_EPISODES]( context, { film_id, page = 1, title= '' } ) {
    return GetAction( `manager/films/${film_id}/episodes`,
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_FILM_EPISODE_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_FILM_EPISODES, response.data );
        context.commit( SET_FILM_EPISODE_PAGE, {page, film_id} );
      }
    } );
  },
  [STORE_FILM_EPISODE]( context, {film_id, payload = {}} ) {
    return PostAction(
    `manager/films/${film_id}/episodes`, payload, context, SET_FILM_EPISODE_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_FILM_EPISODE]( context, payload = {} ) {
    return UpdateAction(
    `manager/episodes`, payload.id, payload, context, SET_FILM_EPISODE_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_FILM_EPISODE, response.data );} }
    );
  },
  [GET_FILM_EPISODE_URL_LIST]( context, film_id ) {
    return GetAction( `manager/films/episodes`,
    `${film_id}/source`,
    context,
    SET_FILM_EPISODE_ERROR, { credentials: true } );
  },
};

const getters = {
  filmEpisode: state=> state.filmEpisode,
  filmEpisodes: state=> state.filmEpisodes,
  filmEpisodeMeta: state=> state.filmEpisodeMeta,
  filmEpisodeError: state=> state.filmEpisodeError,
  filmEpisodePage: state=> state.filmEpisodePage,
};

export default {
  state,
  mutations,
  actions,
  getters
}
