import { GetAction, PostAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_COLLECTION = 'getCollection'; //
export const GET_COLLECTIONS = 'getCollections'; //
export const STORE_COLLECTION = 'storeCollection'; //
export const UPDATE_COLLECTION = 'updateCollection'; //
export const SEARCH_COLLECTION = 'searchCollection'; //

// [NAVIGATION MUTATIONS]
export const SET_COLLECTION = 'setCollection'; //
export const SET_COLLECTIONS = 'setCollections'; //
export const SET_COLLECTION_ERROR = 'setCollectionError'; //
export const SET_SEARCH_COLLECTION_LIST = 'setSearchCollectionList'; //

const state = {
  collection: {},
  collections: [],
  collectionsMeta: {},
  collectionError: null,
  searchCollectionList: [],
  searchCollectionListMeta: {}
};

const mutations = {
  [SET_COLLECTION](state, payload){
    state.collection = payload;
  },
  [SET_COLLECTIONS](state, payload){
    state.collections = payload.data;
    state.collectionsMeta = payload.meta;
  },
  [SET_COLLECTION_ERROR](state, payload){
    state.collectionError = payload;
  },
  [SET_SEARCH_COLLECTION_LIST](state, payload){
    state.searchCollectionList = payload.data;
    state.searchCollectionListMeta = payload.meta || {};
  },
};

const actions = {
  [GET_COLLECTION]( context, id ) {
    return GetAction( `manager/films/collections/${id}`,
    ``,
    context,
    SET_COLLECTION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_COLLECTION, response.data );
      }
    } );
  },
  [GET_COLLECTIONS]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/films/collections",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_COLLECTION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_COLLECTIONS, response.data );
      }
    } );
  },
  [STORE_COLLECTION]( context, payload = {} ) {
    return PostAction(
    "manager/films/collections", payload, context, SET_COLLECTION_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_COLLECTION]( context, payload = {} ) {
    return UpdateAction(
    "manager/films/collections", payload.id, payload, context, SET_COLLECTION_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_COLLECTION, response.data );} }
    );
  },
  [SEARCH_COLLECTION]( context, { title = '', page = '' } ) {
    return GetAction( "manager/films/collections/search",
    `?title=${title || ''}${page ? '&page=' + page : ''}${page ? '&paginate=' + page : ''}`,
    context,
    SET_COLLECTION_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let payload = response.data;
        context.commit( SET_SEARCH_COLLECTION_LIST, payload );
      }
    } );
  },
};

const getters = {
  collection: state=> state.collection,
  collections: state=> state.collections,
  collectionsMeta: state=> state.collectionsMeta,
  collectionError: state=> state.collectionError,
  searchCollectionList: state=> state.searchCollectionList,
  searchCollectionListMeta: state=> state.searchCollectionListMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
