import { GetAction, PostAction, PutAction, UpdateAction } from "../actionHelper";

// [NAVIGATION ACTIONS]
export const GET_SUBSCRIBE_MESSAGE_STOCK = 'getSubscribeMessageStock'; //
export const GET_SUBSCRIBE_MESSAGE_STOCKS = 'getSubscribeMessageStocks'; //
export const UPDATE_SUBSCRIBE_MESSAGE_STOCKS = 'updateSubscribeMessageStocks'; //
export const SEND_SUBSCRIBE_MESSAGE = 'sendSubscribeMessage'; //
export const GET_SUBSCRIBE_MESSAGE_HISTORY = 'getSubscribeMessageHistory'; //

// [NAVIGATION MUTATIONS]
export const SET_SUBSCRIBE_MESSAGE_STOCK = 'setSubscribeMessageStock'; //
export const SET_SUBSCRIBE_MESSAGE_STOCKS = 'setSubscribeMessageStocks'; //
export const SET_SUBSCRIBE_MESSAGE_STOCK_ERROR = 'setSubscribeMessageStockError'; //
export const SET_SUBSCRIBE_MESSAGE_HISTORY = 'setSubscribeMessageHistory'; //

const state = {
  subscribeMessageStock: {},
  subscribeMessageStocks: {},
  subscribeMessageStockError: null,
  subscribeMessageHistories: [],
  subscribeMessageHistoriesMeta: {},
};

const mutations = {
  [SET_SUBSCRIBE_MESSAGE_STOCK](state, payload){
    state.subscribeMessageStock = payload;
  },
  [SET_SUBSCRIBE_MESSAGE_STOCKS](state, payload){
    state.subscribeMessageStocks = payload;
  },
  [SET_SUBSCRIBE_MESSAGE_STOCK_ERROR](state, payload){
    state.subscribeMessageStockError = payload;
  },
  [SET_SUBSCRIBE_MESSAGE_HISTORY](state, payload){
    state.subscribeMessageHistories = payload.data;
    state.subscribeMessageHistoriesMeta = payload.meta;
  },
};

const actions = {
  [GET_SUBSCRIBE_MESSAGE_STOCK]( context, id ) {
    return GetAction( `manager/subscribe_messages/${id}`,
    ``,
    context,
    SET_SUBSCRIBE_MESSAGE_STOCK_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_SUBSCRIBE_MESSAGE_STOCK, response.data );
      }
    } );
  },
  [GET_SUBSCRIBE_MESSAGE_STOCKS]( context, belongs = 'mp' ) {
    return GetAction( `manager/subscribe_messages?from=${belongs}`,
    ``,
    context,
    SET_SUBSCRIBE_MESSAGE_STOCK_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_SUBSCRIBE_MESSAGE_STOCKS, response.data );
      }
    } );
  },
  [UPDATE_SUBSCRIBE_MESSAGE_STOCKS]( context, belongs = 'mp' ) {
    return PostAction(
    "manager/subscribe_messages/update_templates", { 'from': belongs } , context, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_SUBSCRIBE_MESSAGE_STOCKS, response.data );} }
    );
  },
  [SEND_SUBSCRIBE_MESSAGE]( context, { id, formData = {} } ) {
    return PostAction(
    `manager/subscribe_messages/${id}/send`, formData , context, SET_SUBSCRIBE_MESSAGE_STOCK_ERROR,
    { credentials: true, resolved: ( context, response ) => { context.commit( SET_SUBSCRIBE_MESSAGE_STOCKS, response.data );} }
    );
  },
  [GET_SUBSCRIBE_MESSAGE_HISTORY]( context, { id, page = 1 } ) {
    return GetAction( `manager/subscribe_messages/${id}/histories?page=${page}`,
    ``,
    context,
    SET_SUBSCRIBE_MESSAGE_STOCK_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_SUBSCRIBE_MESSAGE_HISTORY, response.data );
      }
    } );
  },
};

const getters = {
  subscribeMessageStock: state=> state.subscribeMessageStock,
  subscribeMessageStocks: state=> state.subscribeMessageStocks,
  subscribeMessageStockError: state=> state.subscribeMessageStockError,
  subscribeMessageHistories: state=> state.subscribeMessageHistories,
  subscribeMessageHistoriesMeta: state=> state.subscribeMessageHistoriesMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
