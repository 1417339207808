import { GetAction, PostAction, PutAction } from "../actionHelper";

// [ACTIONS]
export const GET_USER = 'getUser'; //
export const GET_USER_WEEK_STATISTICS = 'getUserWeekStatistics'; // statistics
export const GET_LATEST_USER = 'getLatestUser'; // getLatestUser
export const GET_USERS = 'getUsers'; //
export const SEARCH_USER = 'searchUser'; //
export const SWITCH_USER_LOCKED = 'switchUserLock'; //
export const UPDATE_USER_REWARD = 'updateUserReward'; //
export const UPDATE_USER_VIP_EXPIRES_IN = 'updateUserVipExpiresIn'; //

// [MUTATIONS]
export const SET_USER = 'setUser'; //
export const SET_USERS = 'setUsers'; //
export const SET_LATEST_USERS = 'setLatestUsers'; //
export const SET_USER_WEEK_STATISTICS = 'setUserWeekStatistics'; //
export const SET_USER_ERROR = 'setUserError'; //
export const SET_SEARCH_USER_LIST = 'setSearchUserList'; //

const state = {
  user: {},
  users: [],
  usersMeta: {},
  userError: null,
  searchUserList: [],
  searchUserListMeta: {},
  latestUsers: [],
  userWeekStatistics: [],
};

const mutations = {
  [SET_USER](state, payload){
    state.user = payload;
  },
  [SET_LATEST_USERS](state, payload){
    state.latestUsers = payload;
  },
  [SET_USER_WEEK_STATISTICS](state, payload){
    state.userWeekStatistics = payload;
  },
  [SET_USERS](state, payload){
    state.users = payload.data;
    state.usersMeta = payload.meta;
  },
  [SET_USER_ERROR](state, payload){
    state.userError = payload;
  },
  [SET_SEARCH_USER_LIST](state, payload){
    state.searchUserList = payload.data;
    state.searchUserListMeta = payload.meta || {};
  },
};

const actions = {
  [GET_LATEST_USER](context){
    return GetAction( `manager/users`,
      `latest`,
      context,
      SET_USER_ERROR,
      { credentials: true, resolved: (context, response)=>{
        context.commit(SET_LATEST_USERS, response.data)
        }
      }
    );
  },
  [GET_USER_WEEK_STATISTICS](context){
    return GetAction( `manager/users`,
    `statistics-week`,
    context,
    SET_USER_ERROR,
    { credentials: true, resolved: (context, response)=>{
        context.commit(SET_USER_WEEK_STATISTICS, response.data)
      }
    }
    );
  },
  [GET_USER]( context, id ) {
    return GetAction( `manager/users/${id}`,
    ``,
    context,
    SET_USER_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_USER, response.data );
      }
    } );
  },
  [GET_USERS]( context, { page = 1, keywords = '', action = 'id' } ) {
    return GetAction( "manager/users",
    `?keywords=${keywords || ''}&action=${action || 'id'}&page=${page || 1}`,
    context,
    SET_USER_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_USERS, response.data );
      }
    } );
  },
  [SEARCH_USER]( context, { title = '', page = false } ) {
    return GetAction( "manager/users/search",
    `?title=${title || ''}${page ? '&page=' + page : ''}`,
    context,
    SET_USER_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let payload = page ? response.data : response;
        context.commit( SET_SEARCH_USER_LIST, payload );
      }
    } );
  },
  [SWITCH_USER_LOCKED](context, {user_id, reason}){
    return PostAction( `manager/users/${user_id}/switch-lock`,
      {reason},
      context,
      SET_USER_ERROR,
      { credentials: true }
    );
  },
  [UPDATE_USER_REWARD](context,{ user_id, payload }){
    return PutAction(
      `manager/users/${user_id}/profit-ratio`,
      payload,
      context,
      SET_USER_ERROR,
      { credentials: true }
    );
  },
  [UPDATE_USER_VIP_EXPIRES_IN](context,{ user_id, payload }){
    return PutAction(
    `manager/users/${user_id}/vip-expires`,
    payload,
    context,
    SET_USER_ERROR,
    { credentials: true }
    );
  }
};

const getters = {
  user: state=> state.user,
  users: state=> state.users,
  latestUsers: state=> state.latestUsers,
  userWeekStatistics: state=> state.userWeekStatistics,
  usersMeta: state=> state.usersMeta,
  userError: state=> state.userError,
  searchUserList: state=> state.searchUserList,
  searchUserListMeta: state=> state.searchUserListMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
