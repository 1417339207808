import LC from "../../common/storage/localStorage";
import { DeleteAction, GetAction, PostAction } from "../actionHelper";
import { SET_FILM_ERROR, STORE_FILM } from "@/store/modules/film.module";

// [COPY ACTIONS]
export const GET_HOME_COMMENTS = 'getHomeComments'; //
export const GET_FILM_COMMENTS = 'getFilmComments'; //
export const GET_COMMENT_DETAIL = 'getCommentDetail'; //
export const DELETE_COMMENT = 'deleteComment'; //
export const REPLY_COMMENT = 'replyComment'; //
// [COPY MUTATIONS]
export const SET_COMMENT_ERROR = 'setCommentError'; //
export const SET_HOME_COMMENTS_PAGE = 'setHomeCommentsPage'; //
export const SET_HOME_COMMENTS = 'setHomeComments'; //
export const SET_FILM_COMMENTS = 'setFilmComments'; //
export const SET_FILM_TITLE = 'setFilmTitle'; //
export const SET_FILM_IMAGE = 'setFilmImage'; //
export const SET_COMMENT_DETAIL = 'setCommentDetail'; //

const state = {
  home_comments: [],
  home_comments_meta: {},
  home_comments_page: 1,
  film_comments_list: [],
  film_comments_meta: {},
  film_title: '',
  film_image: '',
  comment_detail: {},
  error: null
};

const mutations = {
  [SET_HOME_COMMENTS](state, payload){
    state.home_comments = payload.data;
    state.home_comments_meta = payload.meta;
  },
  [SET_COMMENT_ERROR](state, error){
    state.error = error;
  },
  [SET_HOME_COMMENTS_PAGE](state, page = 1){
    state.home_comments_page = page;
  },
  [SET_FILM_COMMENTS](state, payload){
    state.film_comments_list = payload.data;
    state.film_comments_meta = payload.meta;
  },
  [SET_FILM_TITLE](state, title){
    state.film_title = title;
  },
  [SET_FILM_IMAGE](state, image){
    state.film_image = image;
  },
  [SET_COMMENT_DETAIL](state, detail){
    state.comment_detail = detail;
  }
};

const actions = {
  [GET_HOME_COMMENTS](context, { page= 1, filter= 1 } ){
    return GetAction( "manager/films/comments",
    `?filter=${filter}&page=${page}`,
    context,
    SET_COMMENT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_HOME_COMMENTS, response.data );
        context.commit( SET_HOME_COMMENTS_PAGE, page );
      }
    } );
  },
  [GET_FILM_COMMENTS](context, { id, page= 1 } ){
    return GetAction( `manager/films/comments/${id}/commenter`,
    `?page=${page}`,
    context,
    SET_COMMENT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit(SET_FILM_COMMENTS, response.data );
        context.commit(SET_FILM_TITLE, response.data.title );
        context.commit(SET_FILM_IMAGE, response.data.image );
        // context.commit(SET_HOME_COMMENTS_PAGE, page);
      }
    } );
  },
  [GET_COMMENT_DETAIL](context, { id } ){
    return GetAction( `manager/films/comments/${id}`,
    ``,
    context,
    SET_COMMENT_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit(SET_COMMENT_DETAIL, response.data );
      }
    } );
  },
  [DELETE_COMMENT](context, {id}){
    return DeleteAction(
    `manager/films/comments/${id}`, context, SET_COMMENT_ERROR,
    { credentials: true }
    );
  },
  [REPLY_COMMENT]( context, {id, formData = {}} ) {
    return PostAction(
    `manager/films/comments/${id}`, formData, context, SET_COMMENT_ERROR,
    { credentials: true }
    );
  },
};

const getters = {
  home_comments: (state)=> state.home_comments, //
  home_comments_meta: (state)=> state.home_comments_meta, //
  home_comments_page: (state)=> state.home_comments_page, //
  comments_error: (state)=> state.error, //
  film_comments_list: (state)=> state.film_comments_list, //
  film_comments_meta: (state)=> state.film_comments_meta, //
  film_title: (state)=> state.film_title, //
  film_image: (state)=> state.film_image, //
  comment_detail: (state)=> state.comment_detail, //
};

export default {
  state,
  mutations,
  actions,
  getters
}
