import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import handleReject from "../../common/handleReject";
import LC from "../../common/storage/localStorage";
import { ID_TOKEN_KEY } from "../types";
import { GetAction, PostAction, PutAction } from "../actionHelper";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_ADMIN = "updateAdmin";
export const CHANGE_PASSWORD = "changePassword";
export const REFRESH_TOKEN = "refreshToken";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAdmin";
export const SET_AUTH_ERROR = "setError";
export const SET_MENU_ITEMS = "setMenuItems";
export const SET_ENABLE_LIST = "setEnableList";
export const SET_FILM_MEDIA_SOURCE = "setFilmMediaSource";
export const SET_OTHER_SETTING = "setOtherSetting";

const state = {
  authError: null,
  admin: {},
  isAuthenticated: !!JwtService.getToken(),
  menuItems: [],
  enableList: [],
  filmMediaSource: [],
  otherSetting: {},
  contract_permission: false,
  app_permission: false,
  h5_permission: false,
  comment_permission: false,
};

const getters = {
  currentAdmin(state) {
    return state.admin;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  menuItems(state){
    if ( !LC.hasExpired('menuItems') )
      return LC.get('menuItems');
    return state.menuItems;
  },
  enableList(state){
    if ( !LC.hasExpired('enableList') )
      return LC.get('enableList');
    return state.enableList;
  },
  filmMediaSource(state){
    if ( !LC.hasExpired('filmMediaSource') )
      return LC.get('filmMediaSource');
    return state.filmMediaSource;
  },
  authError(state){
    return state.authError
  },
  otherSetting(state){
    if ( !LC.hasExpired('otherSetting') )
      return LC.get('otherSetting');
    return state.otherSetting;
  },
  contract_permission(state){
    if ( !LC.hasExpired('contract_permission') )
      return LC.get('contract_permission');
    return state.contract_permission;
  },
  app_permission(state){
    if ( !LC.hasExpired('app_permission') )
      return LC.get('app_permission');
    return state.app_permission;
  },
  h5_permission(state){
    if ( !LC.hasExpired('h5_permission') )
      return LC.get('h5_permission');
    return state.h5_permission;
  },
  comment_permission(state){
    if ( !LC.hasExpired('comment_permission') )
      return LC.get('comment_permission');
    return state.comment_permission;
  }
};

const handleAuth = (context, data)=>{
  context.commit(SET_AUTH, data);
  LC.destroy('menuItems');
  LC.destroy('enableList');
  LC.destroy('contract_permission');
  LC.destroy('app_permission');
  LC.destroy('h5_permission');
  LC.destroy('comment_permission');
  LC.destroy('filmMediaSource');
  LC.destroy('otherSetting');
  handleCommit(context, data, 'menuItems', SET_MENU_ITEMS);
  handleCommit(context, data, 'enable_list', SET_ENABLE_LIST);
  handleCommit(context, data, 'film_setting', SET_FILM_MEDIA_SOURCE, [], data=> data.hasOwnProperty( 'film_setting' ) ? data.film_setting.media_source : []);
  handleCommit(context, data, 'other_setting', SET_OTHER_SETTING, {});
}
const handleCommit = (context, data, key, types, defaults = [], value = null)=>{
  context.commit(types, data.hasOwnProperty(key) ? typeof value === 'function' ? value(data) : data[key] : defaults);
}

const actions = {
  [LOGIN](context, credentials) {
    return PostAction("manager/authorizations/login", credentials, context, SET_AUTH_ERROR, {
      resolved: (context, response)=> {handleAuth(context, response.data);}
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context, router = null) {
    if (JwtService.getToken()) {
      if ( LC.expiredAtDiffInMinutes(ID_TOKEN_KEY) < 30 ){
        context.dispatch(REFRESH_TOKEN);
      }else {
        return GetAction("manager/authorizations/verify", null, context, SET_AUTH_ERROR, {
          credentials: true,
          resolved: (context, response)=> { handleAuth(context, response.data) }
        });
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_ADMIN](context, payload) {
    const { phone, nickname, avatar } = payload;
    const user = { phone, nickname, avatar };
    return PutAction("manager/administrators", user, context, SET_AUTH_ERROR, {
      credentials: true, resolved: (context, response)=>{ context.commit(SET_AUTH, response.data); }
    });
  },
  [CHANGE_PASSWORD](context, payload) {
    return PostAction("manager/administrators/change-password", payload, context, SET_AUTH_ERROR, {
      credentials: true
    });
  },
  [REFRESH_TOKEN](context){
    return PostAction("manager/authorizations/refresh", null, context, SET_AUTH_ERROR, {
      credentials: true,
      resolved: (context, response)=> {
        handleAuth(context, response.data);
      }
    });
  }
};

const mutations = {
  [SET_AUTH_ERROR](state, error) {
    state.authError = error;
  },
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    state.admin = payload.admin;
    state.authError = {};
    let { token } = payload;
    JwtService.saveToken(token.access_token, token.expires_in);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.admin = {};
    state.authError = {};
    state.enableList = [];
    state.menuItems = [];
    state.contract_permission = false;
    state.app_permission = false;
    state.h5_permission = false;
    state.comment_permission = false;
    state.filmMediaSource = [];
    state.otherSetting = {}
    LC.destroy('menuItems');
    LC.destroy('enableList');
    LC.destroy('filmMediaSource');
    LC.destroy('otherSetting');
    LC.destroy('contract_permission');
    LC.destroy('app_permission');
    LC.destroy('h5_permission');
    LC.destroy('comment_permission');
    JwtService.destroyToken();
  },
  [SET_MENU_ITEMS](state, payload){
    state.menuItems = payload;
    LC.set('menuItems', payload);
  },
  [SET_ENABLE_LIST](state, payload){
    state.enableList = payload;
    LC.set('enableList', payload);
    state.contract_permission = payload.includes('contract_permission');
    LC.set('contract_permission', payload.includes('contract_permission'));
    state.app_permission = payload.includes('app_permission');
    LC.set('app_permission', payload.includes('app_permission'));
    state.h5_permission = payload.includes('h5_permission');
    LC.set('h5_permission', payload.includes('h5_permission'));
    state.comment_permission = payload.includes('comment_permission');
    LC.set('comment_permission', payload.includes('comment_permission'));
  },
  [SET_FILM_MEDIA_SOURCE](state, payload){
    state.filmMediaSource = payload;
    LC.set('filmMediaSource', payload);
  },
  [SET_OTHER_SETTING](state, payload){
    state.otherSetting = payload;
    LC.set('otherSetting', payload);
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
