export default [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/views/theme/Base"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/pages/Dashboard.vue"),
        meta: { requiresAuth: true }
      },
      {
        name: "advert",
        path: "/adverts",
        meta: { requiresAuth: true, title: 'ئېلان باشقۇرۇش' },
        component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
        redirect: 'home',
        children: [
          {
            name: 'advert.home',
            path: 'home',
            meta: { requiresAuth: true, title: 'بارلىق ئېلانلار' },
            component: () => import(/* webpackChunkName: "advert" */ "@/views/pages/advert/home"),
          },
          {
            name: 'advert.store',
            path: 'store',
            meta: { requiresAuth: true, title: 'يېڭىدىن ئېلان قوشۇش' },
            component: () => import(/* webpackChunkName: "advert" */ "@/views/pages/advert/create"),
          },
          {
            name: 'advert.edit',
            path: ':id/edit',
            meta: { requiresAuth: true, title: 'ئېلان ئۇچۇرىنى تەھرىرلەش' },
            component: () => import(/* webpackChunkName: "advert" */ "@/views/pages/advert/edit"),
          },
        ]
      },
      {
        name: "navigation",
        path: "/navigations",
        redirect: 'home',
        component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
        children: [
          {
            name: "navigation.home",
            path: "home",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/navigation/home"),
            meta: { requiresAuth: true, title: 'بارلىق باشلاش تىزىملىكى' }
          },
          {
            name: "navigation.store",
            path: "store",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/navigation/create"),
            meta: { requiresAuth: true, title: 'يېڭىدىن باشلاش تىزىملىكى قوشۇش' }
          },
          {
            name: "navigation.edit",
            path: ":id/edit",
            component: ()=> import(/* webpackChunkName: "navigation" */ "@/views/pages/navigation/edit"),
            meta: { requiresAuth: true, title: 'تىزىملىك ئۇچۇرى تەھرىرلەش' }
          },
        ]
      },
      {
        name: "film",
        path: "/films",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        redirect: 'home',
        children: [
          {
            name: "film.home",
            path: "home",
            meta: { requiresAuth: true, title: 'بارلىق فىلىملەر' },
            component: () => import(/* webpackChunkName: "film" */ "../views/pages/film/home"),
          },
          {
            name: 'film.store',
            path: 'store',
            meta: { requiresAuth: true, title: 'يېڭىدىن فىلىم قوشۇش' },
            component: () => import(/* webpackChunkName: "film" */ "../views/pages/film/create"),
          },
          {
            name: 'film.edit',
            path: ':id/edit',
            meta: { requiresAuth: true, title: 'فىلىم ئۇچۇرى تەھرىرلەش' },
            component: () => import(/* webpackChunkName: "film" */ "../views/pages/film/edit"),
          },
          {
            name: 'film.show',
            path: ':id/show',
            meta: { requiresAuth: true, title: 'فىلىم تەپسىلاتى' },
            component: () => import(/* webpackChunkName: "film" */ "../views/pages/film/show"),
          },
          {
            name: 'film.mp.poster',
            path: 'mp/poster',
            meta: { requiresAuth: true, title: 'ئەپچاق تەشۋىقات تەڭشىكى' },
            component: () => import(/* webpackChunkName: "film" */ "../views/pages/film/poster/mp"),
          },
          {
            name: 'film.h5.poster',
            path: 'h5/poster',
            meta: { requiresAuth: true, title: 'سالون تەشۋىقات تەڭشىكى' },
            component: () => import(/* webpackChunkName: "film" */ "../views/pages/film/poster/h5"),
          },
          {
            name: 'film.invalid',
            path: 'invalid',
            meta: { requiresAuth: true, title: 'چىقمايدىغان فىلىملەر' },
            component: () => import(/* webpackChunkName: "film" */ "../views/pages/film/invalid"),
          },
          {
            name: "episode",
            path: ":film_id/episodes",
            redirect: 'home',
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            children: [
              {
                name: 'episode.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'قىسىم باشقۇرۇش' },
                component: () => import(/* webpackChunkName: "episode" */ "../views/pages/film/episode/home"),
              },
              {
                name: 'episode.store',
                path: 'store',
                meta: { requiresAuth: true, title: 'يېڭى قىسىم قوشۇش' },
                component: () => import(/* webpackChunkName: "episode" */ "../views/pages/film/episode/create"),
              },
              {
                name: 'episode.edit',
                path: ':id/edit',
                meta: { requiresAuth: true, title: 'قىسىم ئۇچۇرى تەھرىرلەش' },
                component: () => import(/* webpackChunkName: "episode" */ "../views/pages/film/episode/edit"),
              },
            ]
          },
          {
            name: "flag",
            path: "flags",
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            redirect: 'home',
            children: [
              {
                name: 'flag.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'بارلىق سەھىپە' },
                component: () => import(/* webpackChunkName: "language" */ "../views/pages/film/flag/home"),
              },
              {
                name: 'flag.store',
                path: 'store',
                meta: { requiresAuth: true, title: 'يېڭىدىن سەھىپە قوشۇش' },
                component: () => import(/* webpackChunkName: "language" */ "../views/pages/film/flag/create"),
              },
              {
                name: 'flag.edit',
                path: ':id/edit',
                meta: { requiresAuth: true, title: 'سەھىپە ئۇچۇرىنى تەھرىرلەش' },
                component: () => import(/* webpackChunkName: "language" */ "../views/pages/film/flag/edit"),
              },
            ]
          },
          {
            name: "category",
            path: "categories",
            meta: { requiresAuth: true },
            redirect: 'home',
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            children: [
              {
                name: 'category.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'بارلىق تۈرلەر' },
                component: () => import(/* webpackChunkName: "category" */ "../views/pages/film/category/home"),
              },
              {
                name: 'category.store',
                path: 'store',
                meta: { requiresAuth: true, title: 'يېڭىدىن تۈر قوشۇش' },
                component: () => import(/* webpackChunkName: "category" */ "../views/pages/film/category/create"),
              },
              {
                name: 'category.edit',
                path: ':id/edit',
                meta: { requiresAuth: true, title: 'تۈر ئۇچۇرى تەھرىرلەش' },
                component: () => import(/* webpackChunkName: "category" */ "../views/pages/film/category/edit"),
              },
            ]
          },
          {
            name: "region",
            path: "regions",
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            redirect: 'home',
            children: [
              {
                name: 'region.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'بارلىق رايونلار' },
                component: () => import(/* webpackChunkName: "region" */ "../views/pages/film/region/home"),
              },
              {
                name: 'region.store',
                path: 'store',
                meta: { requiresAuth: true, title: 'يېڭىدىن رايۇن قوشۇش' },
                component: () => import(/* webpackChunkName: "region" */ "../views/pages/film/region/create"),
              },
              {
                name: 'region.edit',
                path: ':id/edit',
                meta: { requiresAuth: true, title: 'رايۇن ئۇچۇرى تەھرىرلەش' },
                component: () => import(/* webpackChunkName: "region" */ "../views/pages/film/region/edit"),
              },
            ]
          },
          {
            name: "language",
            path: "languages",
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            redirect: 'home',
            children: [
              {
                name: 'language.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'بارلىق تىللار' },
                component: () => import(/* webpackChunkName: "language" */ "../views/pages/film/language/home"),
              },
              {
                name: 'language.store',
                path: 'store',
                meta: { requiresAuth: true, title: 'يېڭىدىن تىل قوشۇش' },
                component: () => import(/* webpackChunkName: "language" */ "../views/pages/film/language/create"),
              },
              {
                name: 'language.edit',
                path: ':id/edit',
                meta: { requiresAuth: true, title: 'تىل ئۇچۇرىنى تەھرىرلەش' },
                component: () => import(/* webpackChunkName: "language" */ "../views/pages/film/language/edit"),
              },
            ]
          },
          {
            name: "collections",
            path: "collections",
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            redirect: 'home',
            children: [
              {
                name: 'collection.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'بارلىق توپلام' },
                component: () => import(/* webpackChunkName: "collections" */ "../views/pages/film/collections/home"),
              },
              {
                name: 'collection.store',
                path: 'store',
                meta: { requiresAuth: true, title: 'يېڭىدىن توپلام قوشۇش' },
                component: () => import(/* webpackChunkName: "collections" */ "../views/pages/film/collections/create"),
              },
              {
                name: 'collection.edit',
                path: ':id/edit',
                meta: { requiresAuth: true, title: 'توپلام ئۇچۇرىنى تەھرىرلەش' },
                component: () => import(/* webpackChunkName: "collections" */ "../views/pages/film/collections/edit"),
              },
            ]
          },
        ]
      },
      {
        name: "user",
        path: "/users",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "user.home",
            meta: { requiresAuth: true, title: 'ئەزا باشقۇرۇش' },
            component: () => import(/* webpackChunkName: "user" */ "../views/pages/user/home"),
          },
          {
            path: ":id/show",
            name: "user.show",
            meta: { requiresAuth: true, title: 'ئەزا تەپسىلاتى' },
            component: () => import(/* webpackChunkName: "user" */ "../views/pages/user/show"),
          },
          {
            name: "wallet",
            path: "wallets",
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            redirect: 'home',
            children: [
              {
                name: 'wallet.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'نەقلەشتۈرۈش ئىلتىماسى باشقۇرۇش' },
                component: () => import(/* webpackChunkName: "wallet" */ "../views/pages/user/wallet/home"),
              },
              {
                name: 'wallet.show',
                path: ':user_id/show',
                meta: { requiresAuth: true, title: 'كىرىم - چىقىم تەپسىلاتى' },
                component: () => import(/* webpackChunkName: "wallet" */ "../views/pages/user/wallet/show"),
              },
            ]
          },
          {
            name: "entrust",
            path: "entrusts",
            meta: { requiresAuth: true },
            component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
            redirect: 'home',
            children: [
              {
                name: 'entrust.home',
                path: 'home',
                meta: { requiresAuth: true, title: 'كېلىشىم ئەزالار' },
                component: () => import(/* webpackChunkName: "entrust" */ "../views/pages/user/entrust/home"),
              },
              {
                name: 'entrust.show',
                path: ':entrust_id/show',
                meta: { requiresAuth: true, title: 'كېلىشىم تەپسىلاتى' },
                component: () => import(/* webpackChunkName: "entrust" */ "../views/pages/user/entrust/show"),
              },
            ]
          },
        ]
      },
      {
        name: "membership",
        path: "/memberships",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            name: 'membership.home',
            path: 'home',
            meta: { requiresAuth: true, title: 'VIP ئەزالىق تۈرى باشقۇرۇش' },
            component: () => import(/* webpackChunkName: "membership" */ "../views/pages/membership/home"),
          },
          {
            name: 'membership.store',
            path: 'store',
            meta: { requiresAuth: true, title: 'يېڭىدىن ئەزالىق تۈرى قوشۇش' },
            component: () => import(/* webpackChunkName: "membership" */ "../views/pages/membership/create"),
          },
          {
            name: 'membership.edit',
            path: ':id/edit',
            meta: { requiresAuth: true, title: 'ئەزالىق تۈرى ئۇچۇرى تەھرىرلەش' },
            component: () => import(/* webpackChunkName: "membership" */ "../views/pages/membership/edit"),
          },
        ]
      },
      {
        name: "subscribe_message",
        path: "/subscribe_messages",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            name: 'subscribe_message.home',
            path: 'home',
            meta: { requiresAuth: true, title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/subscribeMessage/home"),
          },
          {
            name: 'subscribe_message.history',
            path: ':id/history',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش خاتىرىسى' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/subscribeMessage/history"),
          },
          {
            name: 'subscribe_message.send',
            path: ':id/send',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/subscribeMessage/send"),
          },
        ]
      },
      {
        name: "profile",
        path: "/profile",
        redirect: "/profile/edit",
        component: ()=> import("@/views/pages/profile"),
        children: [
          {
            name: "edit.profile",
            path: "edit",
            component: ()=> import("@/views/pages/profile/Edit"),
            meta: { requiresAuth: true }
          },
          {
            name: "change.password",
            path: "change/password",
            component: ()=> import("@/views/pages/profile/ChangePassword"),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        name: "setting",
        path: "/settings",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "store",
            name: "setting.store",
            component: () => import("@/views/pages/setting/create"),
            meta: { requiresAuth: true }
          },
          {
            path: "home",
            name: "setting.home",
            component: () => import("@/views/pages/setting/home"),
            meta: { requiresAuth: true, title: 'بارلىق تەڭشەكلەر' }
          }
        ]
      },
      {
        name: "protocol",
        path: "/protocol",
        component: () => import("@/views/pages/protocol/Content"),
        meta: { requiresAuth: true }
      },
      {
        name: "tools",
        path: "/tools",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "wechat-info",
            name: "wechatInfo",
            component: () => import("@/views/pages/tools/WeChatInfo"),
            meta: { requiresAuth: true }
          },
        ]
      },
      {
        name: "platform",
        path: "/platforms",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'home',
        children: [
          {
            path: "home",
            name: "platform.home",
            meta: { requiresAuth: true, title: 'بارلىق سۇپىلار' },
            component: () => import(/* webpackChunkName: "platform" */ "../views/pages/platform/home"),
          },
          {
            path: "store",
            name: "platform.store",
            meta: { requiresAuth: true, title: 'يېڭىدىن سۇپا قوشۇش' },
            component: () => import(/* webpackChunkName: "platform" */ "../views/pages/platform/store"),
          },
          {
            path: ":id/edit",
            name: "platform.edit",
            meta: { requiresAuth: true, title: 'سۇپا ئۇچۇرى تەھرىرلەش' },
            component: () => import(/* webpackChunkName: "platform" */ "../views/pages/platform/edit"),
          },
          {
            path: ":id/center/home",
            name: "platform.center.home",
            meta: { requiresAuth: true, title: 'ئۇچۇر مەركىزى' },
            component: () => import(/* webpackChunkName: "platform" */ "../views/pages/platform/center/home"),
          },
          {
            path: "white-list",
            name: "platform.white-list.home",
            meta: { requiresAuth: true, title: 'ئاق تىزىملىك' },
            component: () => import(/* webpackChunkName: "platform" */ "../views/pages/platform/whiteList/home"),
          },
          {
            path: "white-list/store",
            name: "platform.white-list.store",
            meta: { requiresAuth: true, title: 'يېڭىدىن ئاق تىزىملىك قوشۇش' },
            component: () => import(/* webpackChunkName: "platform" */ "../views/pages/platform/whiteList/store"),
          },
          {
            path: "white-list/:id/edit",
            name: "platform.white-list.edit",
            meta: { requiresAuth: true, title: 'ئاق تىزىملىك ئۇچۇرى تەھرىرلەش' },
            component: () => import(/* webpackChunkName: "platform" */ "../views/pages/platform/whiteList/edit"),
          },
        ]
      },
      {
        name: "test",
        path: "/test",
        component: () => import("@/views/pages/test"),
        meta: { requiresAuth: true }
      },
      {
        name: "h5",
        path: "/h5",
        component: () => import(/* webpackChunkName: "wrapper" */ "../views/pages/wrapper"),
        meta: { requiresAuth: true },
        redirect: 'subscribe_messages/home',
        children: [
          {
            name: 'h5.subscribe_message.home',
            path: 'subscribe_messages/home',
            meta: { requiresAuth: true, title: 'مۇشتەرى ئۇچۇرى باشقۇرۇش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/subscribeMessage/home"),
          },
          {
            name: 'h5.subscribe_message.history',
            path: 'subscribe_messages/:id/history',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش خاتىرىسى' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/subscribeMessage/history"),
          },
          {
            name: 'h5.subscribe_message.send',
            path: 'subscribe_messages/:id/send',
            meta: { requiresAuth: true, title: 'ئۇچۇر يوللاش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/subscribeMessage/send"),
          },
          {
            name: 'h5.menus.home',
            path: 'menus/home',
            meta: { requiresAuth: true, title: 'تىزىملىك ئۇچۇرى باشقۇرۇش' },
            component: () => import(/* webpackChunkName: "subscribe_message" */ "../views/pages/h5/menus/home"),
          },
        ]
      },
      {
        name: "comment",
        path: "/comments",
        meta: { requiresAuth: true, title: 'ئىنكاس باشقۇرۇش' },
        component: () => import(/* webpackChunkName: "wrapper" */ "@/views/pages/wrapper"),
        redirect: 'home',
        children: [
          {
            name: 'comment.home',
            path: 'home',
            meta: { requiresAuth: true, title: 'بارلىق ئىنكاسلار' },
            component: () => import(/* webpackChunkName: "comment" */ "@/views/pages/comment/home"),
            children: [
              {
                name: 'comment.list',
                path: 'list/:id',
                meta: { requiresAuth: true, title: 'بارلىق ئىنكاسلار' },
                component: () => import(/* webpackChunkName: "comment" */ "@/views/pages/comment/list"),
                props: true
              },
              {
                name: 'comment.detail',
                path: 'detail/:id',
                meta: { requiresAuth: true, title: 'ئىنكاس تەپسىلاتى' },
                component: () => import(/* webpackChunkName: "comment" */ "@/views/pages/comment/detail"),
                props: true
              }
            ]
          },
        ]
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/views/pages/auth/Auth"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/views/pages/auth/Login"),
        meta: { requiresGuest: true }
      }
    ]
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/pages/error/Error-1.vue")
  }
]
